<template>
  <v-card class="elevation-0" style="border-radius:16px;">
    <v-toolbar
      dark
      color="blue darken-3"
      class="mb-1"
      elevation="0"
      style="
        border-top-left-radius: 16 !important;
        border-top-right-radius: 16 !important;
      "
    >
      <span class="font-weight-bold font-size-h4" style="width:15%">{{
        $t("MF.notesLabel")
      }}</span>
      <v-spacer style="width:30%"></v-spacer>
      <v-select
        flat
        solo-inverted
        hide-details
        :items="keys"
        prepend-inner-icon="mdi-magnify"
        :label="$t('MF.sortby')"
        v-model="sortBy"
        v-if="false"
      ></v-select>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        clearable
        flat
        rounded
        dense
        solo-inverted
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('MF.search')"
      ></v-text-field>
    </v-toolbar>
    <v-data-table
      :items="itemsSorted"
      :loading="loadingStatus"
      :locale="this.$root.lang"
      style="border-radius:16px;"
      :headers="headers"
      :items-per-page="5"
      :loading-text="$t('tLoading')"
      :search="search"
    >
      <template v-slot:item.owner.first_name="{ item }">
        <Avatar :user="item.owner"></Avatar>
      </template>
      <template v-slot:item.note_body="{ item }">
        <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
          item.note_body
        }}</span>
      </template>
      <template v-slot:item.created_on="{ item }">
        <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
          item.created_on | moment("dddd, MMMM Do YYYY")
        }}</span>
      </template>
      <template v-slot:item.action="{ item }">
        <button
          class="btn btn-icon btn-warning mx-3"
          @click="updateRecord(item)"
        >
          <em class="flaticon2-edit"></em>
        </button>

        <button class="btn btn-icon btn-danger" @click="deleteItem(item)">
          <em class="flaticon2-delete"></em>
        </button>

        <!-- <a href="#" class="btn btn-icon btn-success">
          <em class="flaticon2-medical-records"></em>
        </a> -->
      </template>
    </v-data-table>

    <EditNote
      @closeDialog="openAddDialog = false"
      @formSubmitted="formSubmitted"
      :dialog="openAddDialog"
      :record="record"
      v-if="openAddDialog"
    />

    <!-- delete item -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this File?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Avatar from "@/view/pages/utilComponents/avatar2.vue";
import EditNote from "./editcomp/EditNote";

export default {
  name: "Notes",
  props: ["mfid"],
  components: { Avatar, EditNote },
  data() {
    return {
      openAddDialog: false,
      dialogDelete: false,
      record: null,
      search: "",
      sortBy: "",
      error: "",
      keys: ["All"],
      loadingStatus: true,
      items: [],
      //historySorted:[],
      headers: [
        {
          text: this.$t("MF.by"),
          align: "left",
          sortable: true,
          value: "owner.first_name"
        },
        { text: this.$t("MF.notes.note"), value: "note_body" },
        { text: this.$t("MF.date"), value: "created_on" },
        {
          text: this.$t("MF.action"),
          value: "action",
          align: "right",
          sortable: false
        }
      ],
      lastSelected: null
    };
  },
  mounted() {
    this.getItems();
    //this.getTypes();
  },
  methods: {
    async getItems() {
      //var userInfo = JSON.parse(localStorage.userInfo).id;

      await ApiService.get(
        "items",
        "md_notes?fields=id,note_body,summary,owner.first_name,owner.last_name,owner.avatar.data,created_on&filter[medical_file]=" +
          this.mfid
      )
        .then(({ data }) => {
          ////console.log(data.data);
          this.items = data.data;
          data.data = data.data.map(d => {
            d.owner =
              d.owner === null
                ? { first_name: "Doctory", last_name: "Doctor" }
                : d.owner;
            return d;
          });
          this.loadingStatus = false;
        })
        .catch(error => {
          this.error = error;
        });
    },

    deleteItem(record) {
      this.record = record;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        this.loadingStatus = true;

        await ApiService.delete("md_notes", this.record.id);

        this.loadingStatus = false;
        this.closeDelete();
        this.record = null;
        this.toast(
          "b-toaster-top-center",
          "success",
          "note deleted successfully"
        );
        this.getItems();
      } catch (error) {
        console.log(error);
      }
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    updateRecord(record) {
      this.record = record;
      this.openAddDialog = true;
    },
    formSubmitted(msg) {
      this.openAddDialog = false;
      this.toast("b-toaster-top-center", "success", msg);
      this.getItems();
    },
    toast(toaster, v, msg, append = false) {
      this.counter++;
      this.$bvToast.toast(msg, {
        title: `Alert`,
        toaster: toaster,
        solid: true,
        variant: v,
        appendToast: append
      });
    }
    /*async getTypes()
    {
      await ApiService.get("items", "vital_types?fields=id,name")
        .then(({ data }) => {
          ////console.log(data.data);
          for(var i = 0; i<data.data.length;i++)
          {
            this.keys.push(data.data[i].name);
          }
          this.filterloading = false;
        }, error => {
                  ////console.log(error);
                  
        })
    }*/
  },
  computed: {
    itemsSorted() {
      return this.items.filter(i => {
        return (
          !this.sortBy ||
          i.vital_type.name === this.sortBy ||
          this.sortBy == "All"
        );
      });
    }
  }
};
</script>
